import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 652.000000 651.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,651.000000) scale(0.100000,-0.100000)">
<path d="M1775 4044 c-213 -31 -411 -140 -530 -292 -58 -73 -117 -187 -146
-282 -20 -63 -24 -97 -24 -215 0 -119 3 -151 23 -215 31 -96 57 -151 106 -225
234 -350 697 -456 1061 -243 42 25 106 77 160 132 75 75 99 107 138 186 65
129 88 227 88 365 0 138 -24 236 -87 363 -102 202 -313 365 -533 411 -61 13
-211 22 -256 15z m462 -289 c50 -21 72 -42 94 -90 39 -84 12 -183 -62 -232
l-42 -28 -238 -3 c-185 -2 -240 -6 -249 -17 -9 -11 -15 -276 -11 -463 2 -66
104 -97 153 -47 23 23 26 35 30 128 4 78 10 112 26 144 82 159 308 160 386 1
26 -53 26 -58 24 -229 l-3 -174 -30 0 -30 0 -5 175 c-4 147 -8 180 -23 203
-57 88 -195 90 -247 4 -15 -25 -20 -50 -20 -112 0 -105 -14 -152 -59 -196 -62
-62 -151 -65 -218 -6 -60 52 -63 70 -63 328 0 193 3 237 16 265 28 60 48 64
286 65 232 0 268 6 302 52 26 35 26 99 0 129 -41 45 -65 48 -394 48 -336 0
-360 -3 -393 -53 -48 -73 -1 -158 95 -174 56 -9 72 -29 47 -60 -21 -24 -111
-11 -156 23 -93 71 -106 185 -30 271 54 62 56 62 435 63 286 0 349 -3 379 -15z"/>
<path d="M4880 3780 c0 -49 -3 -90 -7 -90 -5 0 -21 9 -38 20 -64 42 -154 35
-217 -18 -103 -87 -85 -287 32 -347 61 -30 139 -25 191 14 36 27 39 26 39 -4
0 -22 4 -25 40 -25 l40 0 0 270 0 270 -40 0 -40 0 0 -90z m-49 -146 c78 -75
51 -206 -49 -233 -70 -20 -140 38 -149 120 -12 119 116 191 198 113z"/>
<path d="M3010 3586 l0 -256 40 0 40 0 0 110 0 110 120 0 120 0 0 -110 0 -110
40 0 40 0 0 255 0 255 -40 0 -40 0 0 -110 0 -110 -120 0 -119 0 -3 108 -3 107
-37 3 -38 3 0 -255z"/>
<path d="M3640 3725 c-103 -47 -150 -174 -105 -283 48 -113 181 -153 276 -83
36 27 42 26 37 -4 -5 -24 -3 -25 38 -25 l44 0 0 200 0 200 -40 0 c-33 0 -40
-3 -40 -20 0 -25 -14 -25 -48 0 -37 28 -117 35 -162 15z m162 -92 c73 -70 53
-191 -37 -229 -88 -36 -185 63 -160 163 23 96 128 131 197 66z"/>
<path d="M4145 3722 c-75 -36 -115 -103 -115 -192 0 -135 103 -227 225 -200
21 5 51 18 66 29 36 27 39 26 39 -4 0 -22 4 -25 40 -25 l40 0 0 200 0 200 -40
0 c-36 0 -40 -2 -40 -25 l0 -25 -38 25 c-53 35 -125 42 -177 17z m166 -88 c78
-75 51 -206 -49 -233 -70 -20 -140 38 -149 120 -12 118 117 191 198 113z"/>
<path d="M5043 3718 c68 -166 147 -372 147 -382 0 -8 -16 -51 -35 -96 -19 -45
-35 -86 -35 -91 0 -6 18 -9 41 -7 l40 3 113 271 c146 348 137 316 86 312 l-42
-3 -56 -147 c-31 -81 -58 -145 -60 -143 -2 2 -27 68 -56 147 l-54 143 -47 3
c-36 2 -46 0 -42 -10z"/>
<path d="M3010 3075 c0 -11 12 -15 45 -15 l45 0 0 -150 c0 -143 1 -150 20
-150 19 0 20 7 20 150 l0 150 45 0 c33 0 45 4 45 15 0 12 -19 15 -110 15 -91
0 -110 -3 -110 -15z"/>
<path d="M3373 3010 c-121 -61 -80 -250 54 -250 86 0 135 48 135 131 0 106
-95 166 -189 119z m100 -32 c34 -18 46 -40 47 -84 1 -109 -136 -141 -170 -39
-29 87 48 164 123 123z"/>
<path d="M3760 3019 c-13 -6 -33 -17 -42 -26 -17 -15 -18 -15 -18 5 0 15 -6
22 -20 22 -19 0 -20 -7 -20 -190 0 -183 1 -190 20 -190 18 0 20 7 20 81 l0 80
26 -20 c18 -14 41 -21 71 -21 79 0 123 49 123 138 0 58 -25 97 -76 118 -38 16
-50 16 -84 3z m91 -58 c49 -49 36 -129 -26 -158 -33 -16 -37 -16 -70 0 -62 29
-72 119 -20 165 37 31 80 29 116 -7z"/>
<path d="M4460 3019 c-13 -5 -33 -17 -42 -26 -17 -15 -18 -15 -18 5 0 15 -6
22 -20 22 -19 0 -20 -7 -20 -190 0 -183 1 -190 20 -190 18 0 20 7 20 81 l0 80
26 -20 c33 -26 109 -28 142 -5 48 33 68 127 39 183 -26 52 -97 81 -147 60z
m83 -49 c55 -44 42 -143 -23 -170 -27 -11 -36 -11 -66 3 -40 20 -58 57 -50
107 11 70 86 102 139 60z"/>
<path d="M4020 2919 c0 -98 1 -101 29 -130 23 -23 38 -29 71 -29 22 0 52 7 65
16 23 15 25 15 25 0 0 -9 8 -16 20 -16 19 0 20 7 20 130 0 123 -1 130 -20 130
-18 0 -20 -7 -20 -74 0 -89 -13 -130 -46 -145 -35 -16 -47 -14 -78 10 -25 19
-26 25 -26 115 0 87 -1 94 -20 94 -19 0 -20 -7 -20 -101z"/>

</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
